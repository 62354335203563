.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.interbankNetworkLogo {
  max-width: 35px;
  max-height: 35px;
  margin-right: 2px;
}

.posImage {
  max-width: 100%;
  max-height: 200px;
  border-radius: 10px;
}